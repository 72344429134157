//
// Layouts
//

// Navbar

.navbar {
	background-color: $white;
	border-bottom: solid $border-width $border-color;
	
	.navbar-brand {
		color: $gray-600;
	}
	
	.share {
		display: none;
	}
}

.navbar-nav {
	margin-top: $paragraph-margin-bottom;
	
	.nav-item {
		background-color: $white;
		border-left: solid $border-width $border-color;
		border-right: solid $border-width $border-color;
		border-bottom: solid $border-width $border-color;
		
		&:first-child {
			border-top: solid $border-width $border-color;
		}
	}
	
	.nav-link {
		padding-left: $nav-link-padding-x;
		padding-right: $nav-link-padding-x;
		
		&:not(:hover),
		&:not(:focus) {
			color: $body-color;
		}
	}
}

@include media-breakpoint-up(md) {
	.navbar {
		.share {
			display: flex;
		}
	}
	
	.navbar-toggler {
		display: none;
	}
	
	.navbar-collapse {
		display: block !important;
		background-color: $white;
		border-bottom: solid $border-width $border-color;
	}
	
	.navbar-nav {
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-top: 0;
		
		.nav-item {
			border: 0;
		}
		
		.nav-link {
			position: relative;
			padding-top: $nav-link-padding-x;
			padding-bottom: $nav-link-padding-x;
			
			&:not(:hover),
			&:not(:focus) {
				color: $body-color;
			}
			
			&:hover,
			&:focus {
				color: $primary;
			}
			
			&::before,
			&::after {
				content: "";
				position: absolute;
				left: 50%;
				top: 0;
				width: 0;
				height: 2px;
				background-color: $primary;
				@include transition($transition-base);
				transform: translateX(-50%);
			}
			
			&:hover::before,
			&:hover::after {
				width: 100%;
			}
		}
	}
}

// Footer

.colophon,
.footer {
	padding-top: $navbar-padding-y;
	padding-bottom: $navbar-padding-y;
	background-color: $white;
	border-top: solid $border-width $border-color;
	@include font-size($font-size-sm);
}

.colophon {
	.nav {
		flex-direction: column;
	}
	
	.nav-item {
		border-bottom: solid $border-width $border-color;
		
		&:last-child {
			border-bottom: 0;
		}
	}
	
	.nav-link {
		padding-left: 0;
		padding-right: 0;
	}
	
	@include media-breakpoint-up(md) {
		.nav {
			flex-direction: row;
		}
		
		.nav-item {
			padding: 0 $nav-link-padding-x * .5;
			border-bottom: 0;
			border-right: solid $border-width $border-color;
			
			&:last-child {
				border-right: 0;
			}
		}
		
		.nav-link {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

.credit {
	text-align: center;
}

.pagetop {
	position: fixed;
	bottom: 0;
	right: 0;
	margin: $grid-gutter-width * .5;
	z-index: $zindex-fixed;
	
	a {
		display: inline-block;
		width: 50px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		text-decoration: none;
		color: $white;
		background-color: $primary;
		@include border-radius($border-radius-pill);
	}
}

// Content

.content {
	padding-top: $grid-gutter-width;
	padding-bottom: $grid-gutter-width;
}

.main,
.side {
	@include make-col-ready();
}

.main {
	margin-bottom: $grid-gutter-width;
}

@include media-breakpoint-up(md) {
	.content {
		padding-top: $grid-gutter-width * 2;
		padding-bottom: $grid-gutter-width * 2;
	}
	
	.main {
		margin-bottom: 0;
		@include make-col(8, $grid-columns);
	}
	
	.side {
		@include make-col(4, $grid-columns);
	}
}

@include media-breakpoint-up(xl) {
	.main {
		@include make-col(9, $grid-columns);
	}
	
	.side {
		@include make-col(3, $grid-columns);
	}
}
